import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "./Constants";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

export const Admin2 = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("login")) {
      const login = JSON.parse(localStorage.getItem("login"));
      if (login.isAdmin) {
        axios(`${base_url}/register-user/`)
          .then((res) => {
            console.log(res);
            const users = res.data.map((user) => ({
              email: user.email,
            }));
            setUserInfo(users);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  const [isExpanded, setIsExpanded] = useState(false);
  const handleUploadSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target[0].files[0]);

    setLoading(true);

    axios
      .post(`${base_url}/upload-csv/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        // alert("file upload successfully");
        openModal("success", "file upload successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // alert("error while uploading file");
        let error = "Error while uploading file"
        if(err.response.data.email) {
          error = err.response.data.email
        }
        openModal("warning", error);
      });
  };

  const [addEmail, setAddEmail] = useState("");
  const [addEmailButtonDisable, setAddEmailButtonDisable] = useState(false);
  const handleAddEmailChange = (e) => {
    setAddEmail(e.target.value);
  };
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleAddEmail = () => {
    setAddEmailButtonDisable(true);
    if (addEmail && validateEmail(addEmail)) {
      console.log(addEmail);
      axios
        .post(`${base_url}/register-user/`, { email: addEmail })
        .then((res) => {
          // alert(res.data.message);
          openModal("success", res.data.message);
          setAddEmail("");
          setAddEmailButtonDisable(false);
          axios(`${base_url}/register-user/`)
            .then((res) => {
              console.log(res);
              const users = res.data.map((user) => ({
                email: user.email,
              }));
              setUserInfo(users);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          // alert(err.response.data.email[0]);
          openModal("warning", err.response.data.email[0]);
          setAddEmailButtonDisable(false);
        });
    }
  };

  const [deleteButtonDisable, setDeleteButtonDisable] = useState(false);

  const handleDeleteUser = (email) => {
    setDeleteButtonDisable(true);
    axios
      .delete(`${base_url}/register-user/delete/${email}/`)
      .then((res) => {
        console.log(res);
        if (res.status === 204) {
          // alert("User deleted");
          openModal("success", "User deleted");
          axios(`${base_url}/register-user/`)
            .then((res) => {
              console.log(res);
              const users = res.data.map((user) => ({
                email: user.email,
              }));
              setUserInfo(users);
              setLoading(false);
              setDeleteButtonDisable(false);
            })
            .catch((err) => {
              setLoading(false);
              setDeleteButtonDisable(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        // alert("error while deleting");
        openModal("warning", "error while deleting");
      });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }
  return (
    <div className="my-4 sm:my-6 mx-4 sm:mx-20">
      <div className="container sm:w-full md:w-1/2 lg:w-1/3 py-4 mb-4 sm:mb-6 px-2 mx-auto">
        <div className="border rounded p-3 mb-4 sm:mb-6">
          <div className="flex justify-between items-center">
            <h3 className="font-medium text-md">User Registration</h3>
            <button
              className="text-blue-600"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Collapse" : "Expand"}
            </button>
          </div>
          <hr className="mt-2 mb-2" />
          {isExpanded && (
            <form onSubmit={handleUploadSubmit}>
              <label
                for="file-upload"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Multiple Emails (upload .csv)
              </label>
              <div className="flex mb-3">
                <input
                  className="text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 me-2 w-full"
                  id="file-upload"
                  type="file"
                  accept=".csv"
                />
                <input
                  type="submit"
                  value="Submit"
                  className="text-white bg-[#1d4645] px-3 py-1 rounded font-medium text-sm cursor-pointer"
                />
              </div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Single Email
              </label>
              <div className="flex">
                <input
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 me-2"
                  placeholder="john.doe@company.com"
                  name="addEmail"
                  value={addEmail}
                  onChange={handleAddEmailChange}
                />
                <button
                  type="button"
                  class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${
                    addEmailButtonDisable ? "cursor-not-allowed" : ""
                  }`}
                  onClick={handleAddEmail}
                  disabled={addEmailButtonDisable}
                >
                  Add
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {userInfo.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="table-fixed min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="border-b border-slate-500">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userInfo.map((user, i) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      type="button"
                      class={`text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 ${
                        deleteButtonDisable ? "cursor-not-allowed" : ""
                      }`}
                      onClick={() => handleDeleteUser(user.email)}
                      disabled={deleteButtonDisable}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-lg font-semibold text-center">No data</div>
      )}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};
