import React from "react";
import HeroSection from "./HeroSection";
import CardSection from "./CardSection";
import { Info } from "./Info";

const Landing = ({ loginState }) => {
  return (
    <div id="landing">
      <HeroSection loginState={loginState} />
      <Info />
      <CardSection />
    </div>
  );
};

export default Landing;
