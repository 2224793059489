import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { base_url } from "./Constants";

const AttemptDetails = () => {
  const { id } = useParams();
  const [attemptDetails, setAttemptDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const login = JSON.parse(localStorage.getItem("login"));
    axios
      .get(`${base_url}/attempt-info/${id}`, {
        headers: {
          Authorization: `Bearer ${login.accessToken}`,
        },
      })
      .then((res) => {
        const response = res.data;
        setAttemptDetails(response.attempt_info);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="my-10 mx-4 sm:mx-20">
      {attemptDetails.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="border-b border-slate-500">
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Question</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Answer</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correct Answer</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {attemptDetails.map((attempt, index) => (
                <tr key={attempt.id}>
                  <td className="px-4 py-2 whitespace-nowrap text-center">{index + 1}</td>
                  <td className="px-4 py-2 whitespace-nowrap">{attempt.question}</td>
                  <td
                    className={`px-4 py-2 whitespace-nowrap ${
                      attempt.user_answer === attempt.actual_answer
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    {attempt.user_answer}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">{attempt.actual_answer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-lg font-semibold text-center">No Records to show</div>
      )}
    </div>
  );
};

export default AttemptDetails;
