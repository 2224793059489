import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "./Constants";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./Modal";

const Admin = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("login")) {
      const login = JSON.parse(localStorage.getItem("login"));
      if (login.isAdmin) {
        axios(
          `${base_url}/admin-stats/` /* , {
          headers: {
            Authorization: `Bearer ${login.accessToken}`,
          },
        } */
        )
          .then((res) => {
            const users = res.data.map((user, i) => ({
              id: i,
              name: user.name,
              email: user.email,
              phone: user.phone_number,
              photo: user.photo,
              attemptedQuestions: user.takes.attempted,
              correctAnswers: user.takes.correct,
              instaHandle: user.insta_handle.replaceAll("@", ""),
              country: user.country,
              dob: user.dob,
              takesCount: user.takes.takes_count,
            }));
            setUserInfo(users);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  const handleUploadSubmit = (e) => {
    e.preventDefault();

    console.log(e.target[1].files[0]);

    const formData = new FormData();
    formData.append("is_delete", isDelete);
    formData.append("file", e.target[1].files[0]);

    setLoading(true);

    axios
      .post(`${base_url}/questions-upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        // alert("file upload successfully");
        openModal("success", "file upload successfully");
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        // alert("error while uploading file");
        openModal("warning", "error while uploading file");
      });
  };

  const downloadCSV = () => {
    const headers = [
      "Serail Number",
      "Name",
      "Email",
      "Phone",
      "Attempted Questions",
      "Correct Answers",
      "Instagram",
      "Country",
      "Date of Birth",
      "No. of Attempts",
    ];
    const rows = userInfo.map((user,i) => [
      i+1,
      user.name,
      user.email,
      user.phone,
      user.attemptedQuestions || 0,
      user.correctAnswers || 0,
      user.instaHandle || "",
      user.country || "",
      user.dob || "",
      user.takesCount || 0,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="my-4 sm:my-6 mx-4 sm:mx-20">
      <div className="container sm:w-full md:w-1/2 lg:w-1/3 py-4 mb-4 sm:mb-4 px-2 mx-auto">
        <div className="border rounded p-3">
          <div className="flex justify-between items-center">
            <h3 className="font-medium text-md">File Upload</h3>
            <button
              className="text-blue-600"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Collapse" : "Expand"}
            </button>
          </div>
          <hr className="mt-2 mb-2" />
          {isExpanded && (
            <form onSubmit={handleUploadSubmit}>
              <div className="mb-3">
                <input
                  type="checkbox"
                  id="is-delete"
                  checked={isDelete}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={() => setIsDelete((prevIsDelete) => !prevIsDelete)}
                />
                <label
                  htmlFor="is-delete"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Is Delete
                </label>
              </div>

              <div className="mb-3">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file-upload"
                  type="file"
                  accept=".xlsx"
                />
              </div>

              <input
                type="submit"
                value="Submit"
                className="w-full text-white bg-[#1d4645] px-7 py-2 rounded font-medium text-sm cursor-pointer"
              />
            </form>
          )}
        </div>
      </div>
      {userInfo.length > 0 ? (
        <div>
          <hr />
          <button
            onClick={downloadCSV}
            className="my-4 text-white bg-[#1d4645] px-4 py-2 rounded font-medium"
          >
            Export
          </button>
          <div className="overflow-x-auto">
            <table className="table-fixed min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="border-b border-slate-500">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S.NO.</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Phone
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Attempted Questions
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Correct Answers
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Instagram
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Country
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date of Birth
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No. of Attempts
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userInfo.map((user, i) => {
                  console.log(user);
                  return (
                    <tr key={user.id}>
                      <td className="px-6 py-4 whitespace-nowrap">{i+1}</td>
                      <td className="px-6 py-4 whitespace-nowrap flex justify-center">
                        <img
                          className="h-16 w-16 max-w-16 rounded-full border"
                          src={user.photo}
                          alt=""
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.attemptedQuestions ? user.attemptedQuestions : 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.correctAnswers ? user.correctAnswers : 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link to={`http://www.instagram.com/${user.instaHandle}`} target="_blank" className="text-emerald-800	hover:text-emerald-950">
                          {user.instaHandle ? user.instaHandle : ""}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.country ? user.country : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.dob ? user.dob : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.takesCount ? user.takesCount : 0}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-lg font-semibold text-center">No data</div>
      )}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default Admin;
