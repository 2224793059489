import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay"; // Import the autoplay CSS
import { Autoplay } from "swiper/modules"; // Import the autoplay module
import "./HeroSectionStyles.css";

const HeroSection = ({ loginState }) => {
  const [username, setUsername] = useState("");
  // const [timeRemaining, setTimeRemaining] = useState({
  //   days: "00",
  //   hours: "00",
  //   minutes: "00",
  //   seconds: "00",
  // });

  useEffect(() => {
    if (localStorage.getItem("login")) {
      const login = JSON.parse(localStorage.getItem("login"));
      if (login) {
        setUsername(login.username);
      }
    }
  }, []);

  // useEffect(() => {
  //   const countdownDate = new Date("July 27, 2024 22:00:00").getTime();

  //   const updateTimer = () => {
  //     const now = new Date().getTime();
  //     const distance = countdownDate - now;

  //     if (distance < 0) {
  //       setTimeRemaining({
  //         days: "00",
  //         hours: "00",
  //         minutes: "00",
  //         seconds: "00",
  //       });
  //       return;
  //     }

  //     const days = String(
  //       Math.floor(distance / (1000 * 60 * 60 * 24))
  //     ).padStart(2, "0");
  //     const hours = String(
  //       Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //     ).padStart(2, "0");
  //     const minutes = String(
  //       Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  //     ).padStart(2, "0");
  //     const seconds = String(
  //       Math.floor((distance % (1000 * 60)) / 1000)
  //     ).padStart(2, "0");

  //     setTimeRemaining({ days, hours, minutes, seconds });
  //   };

  //   updateTimer();
  //   const interval = setInterval(updateTimer, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      {username ? (
        <div>
          <span className="username-mobile font-semibold text-center py-2 border-b">
            {username}
          </span>
        </div>
      ) : (
        ""
      )}
      <div id="hero-section" className="sm:mx-6 mx-2 sm:my-10 my-2 ">
        {/* <div
          className="p-10 bg-slate-400 sm:m-10 m-4 rounded-xl"
          style={{
            boxShadow: "inset #999 0px 0px 10px 0px",
          }}
        >
          <h1 className="text-center sm:text-4xl text-2xl font-bold text-slate-950 mb-4">
            Registration will be live on 27th July 2024 10PM
          </h1>
          <div className="text-center sm:text-5xl text-3xl font-semibold text-slate-950">
            <div className="flex justify-center space-x-2 mb-2">
              <div>
                <div>{timeRemaining.days} </div>
                <p className="sm:text-xl text-sm font-bold">days</p>
              </div>
              <div>:</div>
              <div>
                <div>{timeRemaining.hours}</div>
                <p className="sm:text-xl text-sm font-bold">hours</p>
              </div>
              <div>:</div>
              <div>
                <div>{timeRemaining.minutes}</div>
                <p className="sm:text-xl text-sm font-bold">minutes</p>
              </div>
              <div>:</div>
              <div>
                <div>{timeRemaining.seconds}</div>
                <p className="sm:text-xl text-sm font-bold">seconds</p>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="flex flex-row justify-evenly sm:px-6 px-2 sm:py-6 py-2"
          id="desktopHero"
        >
          <div className="flex flex-col justify-between items-center w-1/3">
            <h1 className="text-[#1d4645] font-bold sm:text-3xl text-md sm:mb-10 mb-2 text-center">
              Host / Presenter
            </h1>
            <img
              src="./host.jpg"
              className="h-auto w-full slide-in-left rounded-lg shadow-xl sm:mb-6 mb-2"
              alt="Girl"
            />
            <h2 className="text-[#1d4645] font-bold sm:text-2xl text-md">
              Shreya Chhapru
            </h2>
          </div>
          <div className="flex flex-col justify-end items-center  w-1/3">
            <h1 className="text-[#1d4645] font-bold sm:text-3xl text-md sm:mb-10 mb-2 text-center">
              The Quiz Master
            </h1>
            <img
              src="./robot1.png"
              alt="Hero"
              className="h-auto w-full slide-in-right border rounded-lg shadow-xl sm:mb-6 mb-2"
            />
            <h2 className="text-[#1d4645] font-bold sm:text-2xl text-md">QJ</h2>
          </div>
        </div>
        <div id="mobileHero">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            loop
            autoplay={{ delay: 4000 }} // Set the delay to 4000ms (4 seconds)
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <div className="flex flex-col justify-between items-center h-auto w-2/3 mx-auto mb-5">
                <h1 className="text-[#1d4645] font-bold sm:text-3xl text-md sm:mb-10 mb-2">
                  Host / Presenter
                </h1>
                <img
                  src="./host.jpg"
                  className="h-auto w-full rounded-lg shadow-xl sm:mb-6 mb-2"
                  alt="Girl"
                />
                <h2 className="text-[#1d4645] font-bold sm:text-2xl text-md">
                  Shreya Chhapru
                </h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-end items-center h-auto w-2/3 mx-auto mb-5">
                <h1 className="text-[#1d4645] font-bold sm:text-4xl text-md sm:mb-10 mb-2">
                  The Quiz Master
                </h1>
                <img
                  src="./robot1.png"
                  className="h-auto w-full border rounded-lg shadow-xl sm:mb-6 mb-2"
                  alt="Hero"
                />
                <h2 className="text-[#1d4645] font-bold sm:text-2xl text-md">
                  QJ
                </h2>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
