import React, { useEffect, useState } from "react";
import MyDialog from "./MyDialog";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";

const Timer = ({ submit, handleQuizSubmit, showAttemptedQuestions }) => {
  const [time, setTime] = useState(90);
  const [formattedTime, setFormattedTime] = useState("");
  const [hiddenTime, setHiddenTime] = useState(null);

  useEffect(() => {
    const updateTimer = () => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          return 0;
        }
        if (submit) {
          return prevTime;
        }
        return prevTime - 1;
      });
    };

    const timer = setInterval(() => {
      if (document.visibilityState === "visible") {
        updateTimer();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [submit]);

  useEffect(() => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    setFormattedTime(`${formattedMinutes}:${formattedSeconds}`);

    if (time === 0) {
      handleQuizSubmit();
    }
  }, [time]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setHiddenTime(new Date().getTime());
      } else if (document.visibilityState === "visible" && hiddenTime) {
        const currentTime = new Date().getTime();
        const timeAway = Math.floor((currentTime - hiddenTime) / 1000);
        setTime((prevTime) => Math.max(prevTime - timeAway, 0));
        setHiddenTime(null);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [hiddenTime]);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  useEffect(() => {
    if (submit && time === 0) {
      openModal(
        "info",
        "Time to attempt the quiz is over, you quiz answers were submitted, Thanks."
      );
    }
    if (submit && time > 0) {
      openModal("info", "Your Quiz Answers were Submitted, Thanks.");
    }
  }, [time, submit]);

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white sticky top-0 shadow-md">
        <p className="text-1xl font-semibold p-2 px-3 sm:mx-20 mx-10 flex justify-between">
          <span>Timer: {formattedTime} (minutes)</span>
          <span>Attempted Questions: {showAttemptedQuestions}/15</span>
        </p>
      </div>
      {/* <MyDialog time={time} submit={submit} /> */}
      <Modal
        show={showModal}
        onClose={() => {
          navigate("/");
          setShowModal(false);
        }}
        message={modalMessage}
        type={modalType}
      />
    </>
  );
};

export default Timer;
