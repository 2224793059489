import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "./Constants";
import Timer from "./Timer";
import { useNavigate } from "react-router-dom";

const QuizPage = ({ takeIdState, loginState, handleTakeIdState }) => {
  const navigate = useNavigate();

  const [questionState, setQuestionState] = useState([]);
  const [submit, setSubmit] = useState(false);

  const handleCheck = (qId, cId) => {
    const updatedQuestions = questionState.map((question) => {
      if (question.questionId === qId) {
        const updatedChoices = question.choices.map((choice) => ({
          ...choice,
          checked: choice.choiceId === cId,
        }));
        return { ...question, choices: updatedChoices };
      }
      return question;
    });
    setQuestionState(updatedQuestions);

    let attempted = 0;
    updatedQuestions.forEach((uq) => {
      uq.choices.forEach((c) => {
        if (c.checked) {
          attempted = attempted + 1;
        }
      });
    });
    handleShowAttemptedQuestions(attempted);
  };

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleQuizSubmit = () => {
    setDisableSubmitButton(true);

    const answers = [];

    questionState.forEach((e) => {
      let answer = e.choices.find((x) => x.checked === true);
      if (answer) {
        let obj = {
          question_id: e.questionId,
          answer: answer.choice,
        };
        answers.push(obj);
      }
    });

    const response = {
      take_id: takeIdState,
      answers: answers,
    };
    const login = JSON.parse(localStorage.getItem("login"));

    axios
      .post(`${base_url}/submit-quiz/`, response, {
        headers: {
          Authorization: `Bearer ${login.accessToken}`,
        },
      })
      .then((res) => {
        setSubmit(true);
        setTimeout(() => {
          handleTakeIdState();
        }, 5000);
        setDisableSubmitButton(false);
      })
      .catch((err) => {
        setDisableSubmitButton(false);
      });
  };

  useEffect(function () {
    const login = JSON.parse(localStorage.getItem("login"));

    if (takeIdState && questionState.length <= 0) {
      axios
        .get(`${base_url}/random-questions/`, {
          headers: {
            Authorization: `Bearer ${login.accessToken}`,
          },
        })
        .then((res) => {
          const newQuestionState = [];

          res.data.forEach((question) => {
            newQuestionState.push({
              questionId: question.id,
              question: question.question,
              choices: [
                {
                  choiceId: 0,
                  choice: question.choice_1,
                  checked: false,
                },
                {
                  choiceId: 1,
                  choice: question.choice_2,
                  checked: false,
                },
                {
                  choiceId: 2,
                  choice: question.choice_3,
                  checked: false,
                },
                {
                  choiceId: 3,
                  choice: question.choice_4,
                  checked: false,
                },
              ],
            });
          });

          setQuestionState(newQuestionState);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });

  useEffect(function () {
    if (!loginState || !takeIdState) {
      navigate("/");
    }
  });

  const [showAttemptedQuestions, setShowAttemptedQuestions] = useState(0);
  const handleShowAttemptedQuestions = (newShowAttemptedQuestions) => {
    setShowAttemptedQuestions(newShowAttemptedQuestions);
  };

  return loginState ? (
    <div className="bg-gray-100 pt-4">
      {/* timer */}
      <Timer
        submit={submit}
        handleQuizSubmit={handleQuizSubmit}
        handleTakeIdState={handleTakeIdState}
        showAttemptedQuestions={showAttemptedQuestions}
      />

      {/* questions */}
      {questionState.map((questions, i) => {
        return (
          <>
            <p
              key={questions.questionId}
              className="bg-white text-xl sm:mx-20 mx-10 font-semibold p-2 px-3 rounded-xl mt-4 border"
            >
              Question {i + 1} : {questions.question}
            </p>
            <div className="flex flex-wrap sm:mx-20 mx-10 bg-gray-100 mt-6">
              {questions.choices.map((choice) => {
                return (
                  <div
                    key={questions.questionId + choice.choiceId}
                    className="sm:w-1/2 w-full "
                    onClick={() =>
                      handleCheck(questions.questionId, choice.choiceId)
                    }
                  >
                    <div className="bg-white rounded-xl hover:bg-gray-200 cursor-pointer border flex p-5 px-6 m-1">
                      <p className="font-bold flex-grow">{choice.choice}</p>
                      {choice.checked && (
                        <div className="border border-black rounded-full cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="border my-6"></div>
          </>
        );
      })}

      <div className="text-center">
        <button
          className="bg-blue-500 text-white font-bold px-12 sm:py-4 py-3 text-1xl w-[150px] rounded-3xl mt-2 mb-2"
          onClick={handleQuizSubmit}
          disabled={disableSubmitButton}
        >
          SUBMIT
        </button>
      </div>
    </div>
  ) : null;
};

export default QuizPage;
