import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "./Constants";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import Modal from "./Modal"; // Import the Modal component
import parse from "html-react-parser";

const CardSection = () => {
  const navigate = useNavigate();
  const [attempts, setAttempts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bestAttempt, setBestAttempt] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("info");

  const handleClick = (id) => {
    navigate(`/attempt/${id}`);
  };

  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("login")) {
      const login = JSON.parse(localStorage.getItem("login"));

      if (login) {
        setIsLoggedIn(true);

        if (attempts.length === 0) {
          axios
            .get(`${base_url}/user-attempts/`, {
              headers: {
                Authorization: `Bearer ${login.accessToken}`,
              },
            })
            .then((res) => {
              const newAttempts = res.data.map((attempt, i) => ({
                id: i,
                takeId: attempt.take_id,
                startTime: attempt.start_time,
                attempts: attempt.attempts,
                correct: attempt.correct,
              }));
              setAttempts(newAttempts);

              // Determine the best attempt
              if (newAttempts.length > 0) {
                const best = newAttempts.reduce((prev, current) =>
                  prev.correct > current.correct ? prev : current
                );
                setBestAttempt(best);
              }
            })
            .catch((err) => console.error(err));
        }
      } else {
        setIsLoggedIn(false);
      }
    }
  }, [location.pathname]);

  const handleDownloadImage = (correct, total) => {
    const canvasElement = document.createElement("div");
    canvasElement.style.width = "200px";
    canvasElement.style.height = "200px";
    canvasElement.style.position = "relative";
    canvasElement.style.backgroundImage = "url('./robot1.png')";
    canvasElement.style.backgroundSize = "cover";

    const overlayElement = document.createElement("div");
    overlayElement.style.position = "absolute";
    overlayElement.style.top = "0";
    overlayElement.style.left = "0";
    overlayElement.style.width = "100%";
    overlayElement.style.height = "100%";
    overlayElement.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // semi-transparent black

    const textElement = document.createElement("div");
    textElement.style.position = "absolute";
    textElement.style.top = "50%";
    textElement.style.left = "50%";
    textElement.style.transform = "translate(-50%, -50%)";
    textElement.style.color = "white";
    textElement.style.fontSize = "16px";
    textElement.style.fontWeight = "bold";
    textElement.style.textAlign = "center";
    textElement.innerText = `I scored ${correct}/${total}, you can try too!`;

    overlayElement.appendChild(textElement);
    canvasElement.appendChild(overlayElement);
    document.body.appendChild(canvasElement);

    html2canvas(canvasElement, { useCORS: true }).then((canvas) => {
      const base64image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = base64image;
      link.download = `share.png`;
      link.click();

      // Show the modal instead of alert
      setModalMessage(
        parse(
          "1) Post the image on Instagram <br/>  2) Tag our official handle @theqjchallenge"
        )
      );
      setModalType("info"); // You can change the type to 'success' if needed
      setModalOpen(true);

      document.body.removeChild(canvasElement);
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {isLoggedIn && (
        <div className="bg-[#1d4645] sm:p-12 p-4 mb-12">
          <h1 className="text-white sm:text-4xl text-2xl font-bold my-4 text-center">
            Your Attempts
          </h1>

          <div className="flex justify-center sm:flex-row flex-col">
            {attempts.length > 0 ? (
              attempts.map((attempt, index) => (
                <div
                  key={attempt.takeId}
                  className="flex-row sm:p-10 px-12 py-4"
                >
                  <div
                    className="bg-zinc-100 sm:py-6 py-4 sm:px-10 px-6 text-1xl font-bold rounded-xl cursor-pointer"
                    onClick={() => handleClick(attempt.takeId)}
                  >
                    <p className="text-zinc-800">Attempt: {index + 1}</p>
                    <p className="text-zinc-800">
                      Questions Attempted: {attempt.attempts}
                    </p>
                    <p className="text-zinc-800">
                      Correct Answers: {attempt.correct}
                    </p>
                    <button
                      className="w-full bg-slate-700 hover:bg-slate-800 my-2 px-4 py-2 rounded text-white font-medium text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadImage(attempt.correct, attempt.attempts);
                      }}
                    >
                      Share
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-2xl font-bold text-yellow-300 mt-8 text-center">
                You haven't made any attempts yet
              </p>
            )}
          </div>

          {bestAttempt && (
            <div>
              <h1 className="text-white sm:text-4xl text-2xl font-bold my-4 text-center mb-10 mt-14">
                Your Best Attempt
              </h1>
              <div className="flex justify-center sm:flex-row flex-col mb-10 ">
                <div className="bg-[#dcfce7] sm:py-6 py-4 sm:px-10 px-6 text-1xl font-bold rounded-xl lg:w-1/2 w-full text-center">
                  <p className="text-zinc-800 text-xl">
                    Your best attempt is {bestAttempt.correct} out of 15.
                    {bestAttempt.correct >= 10
                      ? "If shortlisted, you will be contacted by one of our representatives for further process."
                      : "Unfortunately, we regret to inform that you did not clear the first stage."}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        show={modalOpen}
        onClose={handleCloseModal}
        message={modalMessage}
        type={modalType}
      />
    </>
  );
};

export default CardSection;
