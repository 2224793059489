import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "./Constants";
import Modal from "./Modal";

const Login = ({ handleLogin }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleFormValue = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    // alert("You have entered an invalid email address!");
    openModal("warning", "You have entered an invalid email address!");
    return false;
  }

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (form.email && form.password) {
      if (validateEmail(form.email)) {
        if (form.email === "admin@admin.com" && form.password === 'admin') {
          handleLogin({
            isAdmin: true,
            email: form.email,
          });
          navigate("/verify-master");
        } else {
          axios
            .post(`${base_url}/login/`, {
              email: form.email,
              password: form.password,
            })
            .then(function (response) {
              if (response.status === 200) {
                handleLogin(response.data);
                navigate("/");
              }
            })
            .catch(function (error) {
              // alert(error.response.data.message);
              openModal("warning", error.response.data.message);
            });
        }
      }
    } else {
      // alert("Please fill form");
      openModal("warning", "Please fill form");
    }
  };

  return (
    <div className="sm:w-1/3 w-2/3 pb-4 mx-auto my-28">
      <h1 className="text-xl font-bold mb-5">Login</h1>
      <form onSubmit={handleFormSubmit}>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="email"
          name="email"
          placeholder="Email"
          value={form.email}
          onChange={handleFormValue}
        />

        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="password"
          name="password"
          placeholder="Password"
          value={form.password}
          onChange={handleFormValue}
        />

        <input
          className="text-white bg-[#1d4645] hover:bg-[#0f3332] focus:ring-4 focus:outline-none focus:ring-[#1d46454d] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer"
          type="submit"
          placeholder="Login"
        />
      </form>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default Login;
