import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "./Constants";
import Modal from "./Modal";
import countryList from "react-select-country-list";
import "./RegisterStyles.css";

const Register = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  const [country, setCountry] = useState([]);
  useEffect(() => {
    if (country.length === 0) {
      setCountry(countryList().getData());
    }
  }, [country]);

  const [form, setForm] = useState({
    email: "",
    phoneNumber: "",
    name: "",
    dob: "",
    country: "",
    pfp: "",
    instaHandle: "",
    password: "",
  });

  const handleFormValue = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  function validateEmail(mail) {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegex.test(mail)) {
      return true;
    }
    openModal("warning", "You have entered an invalid email address!");
    return false;
  }

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { email, name, password, phoneNumber, dob, country, pfp } = form;

    if (!email) {
      openModal("warning", "Email is required!");
      return;
    }
    if (!name) {
      openModal("warning", "Name is required!");
      return;
    }
    if (!password) {
      openModal("warning", "Password is required!");
      return;
    }
    if (!phoneNumber) {
      openModal("warning", "Phone number is required!");
      return;
    }
    if (!dob) {
      openModal("warning", "Date of birth is required!");
      return;
    }
    if (!country) {
      openModal("warning", "Country is required!");
      return;
    }
    if (!pfp) {
      openModal("warning", "Profile picture is required!");
      return;
    }

    if (validateEmail(email)) {
      if (email === "admin@admin.com") {
        openModal("warning", "Email not supported");
      } else {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("phone_number", phoneNumber);
        formData.append("name", name);
        formData.append("password", password);
        formData.append("photo", e.target[7].files[0]);
        formData.append("insta_handle", form.instaHandle);
        formData.append("country", country);
        formData.append("dob", dob);

        axios
          .post(`${base_url}/signup/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            openModal("info", res.data.message);
            navigate("/login");
          })
          .catch((err) => {
            let errMessage = "";
            if (err.response) {
              if (err.response.data.email) {
                errMessage += err.response.data.email[0] + "\n";
              }
              if (err.response.data.phone_number) {
                errMessage += err.response.data.phone_number[0].replace(
                  "this",
                  "phone number"
                );
              }
              if (err.response.data.message) {
                errMessage += err.response.data.message;
              }
              if (err.response.status === 413) {
                errMessage +=
                  "Image too large, please select a smaller image";
              }
              errMessage = errMessage.replaceAll("custom", "");
            } else {
              errMessage = "server error";
            }
            openModal("warning", errMessage);
          });
      }
    }
  };

  return (
    <div className="sm:w-1/3 w-2/3 py-4 mx-auto">
      <h1 className="text-xl font-bold mb-5">Register</h1>
      <form onSubmit={handleFormSubmit}>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="text"
          name="name"
          placeholder="Name *"
          value={form.name}
          onChange={handleFormValue}
        />

        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="email"
          name="email"
          placeholder="Email *"
          value={form.email}
          onChange={handleFormValue}
        />

        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number *"
          value={form.phoneNumber}
          onChange={handleFormValue}
        />

        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="password"
          name="password"
          placeholder="Password *"
          value={form.password}
          onChange={handleFormValue}
        />

        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          type="text"
          name="instaHandle"
          placeholder="Instagram Handle"
          value={form.instaHandle}
          onChange={handleFormValue}
        />

        <select
          name="country"
          id="country"
          placeholder="Country *"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-2 sm:mb-3"
          value={form.country}
          onChange={handleFormValue}
        >
          <option value="">Select Country</option>
          {country.map((a) => (
            <option key={a.value} value={a.label}>
              {a.label}
            </option>
          ))}
        </select>

        <div className="mb-2 sm:mb-3 flex items-center">
          <label htmlFor="default-datepicker" className="text-nowrap me-2">
            Date of birth *
          </label>
          <input
            id="default-datepicker"
            type="date"
            name="dob"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Select date"
            value={form.dob}
            onChange={handleFormValue}
          />
        </div>

        <div className="mb-2 sm:mb-3 flex items-center">
          <label htmlFor="file_input" className="text-nowrap me-2">
            Profile picture *
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 custom-file-input"
            id="file_input"
            type="file"
            accept=".png, .jpg, .jpeg"
            name="pfp"
            title=""
            onChange={(e) => setForm({ ...form, pfp: e.target.files[0] })}
          />
        </div>

        <div className="flex justify-center mt-10">
          <input
            className="text-white bg-[#1d4645] hover:bg-[#0f3332] focus:ring-4 focus:outline-none focus:ring-[#1d46454d] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer"
            type="submit"
            placeholder="Login"
          />
        </div>
      </form>
      <h4 className="my-10 font-medium p-5 text-center bg-slate-300 rounded">
        For any issues regarding registration, please whatsapp on{" "}
        <a
          href="tel:+971545201426"
          className="text-teal-700 hover:text-teal-500"
        >
          +971 54 520 1426
        </a>
      </h4>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default Register;
