// Modal.jsx
import React from 'react';
import { ExclamationIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';

const Modal = ({ show, onClose, message, type }) => {
  if (!show) return null;

  const getTypeStyles = (type) => {
    switch (type) {
      case 'warning':
        return {
          header: 'text-yellow-600',
          button: 'bg-yellow-600 hover:bg-yellow-700',
          icon: <ExclamationIcon className="h-6 w-6 text-yellow-600" />
        };
      case 'success':
        return {
          header: 'text-green-600',
          button: 'bg-green-600 hover:bg-green-700',
          icon: <CheckCircleIcon className="h-6 w-6 text-green-600" />
        };
      case 'info':
        return {
          header: 'text-blue-600',
          button: 'bg-blue-600 hover:bg-blue-700',
          icon: <InformationCircleIcon className="h-6 w-6 text-blue-600" />
        };
      default:
        return {
          header: 'text-gray-600',
          button: 'bg-gray-600 hover:bg-gray-700',
          icon: null
        };
    }
  };

  const styles = getTypeStyles(type);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg p-6 max-w-md mx-auto z-10">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            {styles.icon}
            <h3 className={`text-lg font-semibold ml-2 ${styles.header}`}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </h3>
          </div>
          {/* <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button> */}
        </div>
        <p className="mb-4 text-gray-700">{message}</p>
        <div className="flex justify-end">
          <button onClick={onClose} className={`text-white px-4 py-2 rounded-md ${styles.button}`}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
