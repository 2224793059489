export const Info = () => {
  return (
    <div className="bg-[#1d4645] sm:p-12 p-4 mb-12 text-white text-center flex justify-center ">
      <div className="bg-[#172c2c] p-10 rounded-lg lg:w-1/2 w-full">
        <h4 className="text-4xl mb-4 font-medium">Total attempts allowed: 3</h4>
        <h5 className="text-2xl font-semibold">
          Each attempt will have 15 questions with 4 possible answers to be
          completed in 90 seconds.
        </h5>
      </div>
    </div>
  );
};
