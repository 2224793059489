import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Nav = ({
  loginState,
  setLoginState,
  setQuestionId,
  disableQuizStart,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");

  useEffect(
    function () {
      if (localStorage.getItem("login")) {
        const login = JSON.parse(localStorage.getItem("login"));
        if (login) {
          if (login.isAdmin) {
            setLoginState(true);
            if (
              location.pathname !== "/verify-master" &&
              location.pathname !== "/admin-registered-users"
            ) {
              navigate("/verify-master");
            }
          } else {
            if (
              location.pathname === "/login" ||
              location.pathname === "/register"
            ) {
              navigate("/");
            }
            const newLogin = login.loggedIn;
            setLoginState(newLogin);
            setUsername(login.username);
          }
        }
      }
    },
    [loginState, location.pathname, setLoginState]
  );

  useEffect(function () {});

  const logout = () => {
    navigate("/");
    localStorage.removeItem("login");
    setLoginState();
    window.location.reload();
  };

  return (
    <nav className="sm:px-10 px-3 py-2 flex justify-between items-center border-b">
      <Link to="/" className="flex items-center">
        <img
          src="./logo.png"
          alt=""
          className="w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] rounded-full sm:me-6 me-2"
        />
        <h1 className="text-[#1d4645] font-bold sm:text-4xl text-sm">The QJ Challenge!</h1>
      </Link>
      <div>
        {!loginState ? (
          <>
            {location.pathname === "/" ? (
              <>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                  to="/login"
                >
                  Login
                </Link>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                  to="/register"
                >
                  Register
                </Link>
              </>
            ) : location.pathname === "/login" ? (
              <>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                  to="/register"
                >
                  Register
                </Link>
              </>
            ) : location.pathname === "/register" ? (
              <>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                  to="/login"
                >
                  Login
                </Link>
              </>
            ) : null}
          </>
        ) : null}
        {loginState ? (
          location.pathname === "/" ? (
            <>
              {username ? (
                <span className="username me-5 font-semibold">{username}</span>
              ) : (
                ""
              )}
              <button
                className="md:px-10 md:py-3 px-2 py-1 md:me-3 me-1 md:text-base text-xs border border-black rounded self-start font-medium"
                onClick={setQuestionId}
                disabled={disableQuizStart}
              >
                Participate Now
              </button>
              <button
                className="md:px-10 md:py-3 px-2 py-1 md:me-3 me-1 md:text-base text-xs text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                onClick={logout}
              >
                Logout
              </button>
            </>
          ) : location.pathname === "/quiz" ||
            location.pathname.includes("/attempt/") ? (
            <>
              {username ? (
                <span className="username me-5 font-semibold">{username}</span>
              ) : (
                ""
              )}
              <Link
                className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                to="/"
              >
                Home
              </Link>
              <button
                className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                onClick={logout}
              >
                Logout
              </button>
            </>
          ) : location.pathname === "/verify-master" ||
            location.pathname === "/admin-registered-users" ? (
            <>
              {username ? (
                <span className="username me-5 font-semibold">{username}</span>
              ) : (
                ""
              )}
              {location.pathname === "/verify-master" ? (
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                  to="/admin-registered-users"
                >
                  Register User
                </Link>
              ) : (
                ""
              )}
              {location.pathname === "/admin-registered-users" ? (
                <Link
                  className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm border border-black rounded self-start font-medium"
                  to="/verify-master"
                >
                  User Stats
                </Link>
              ) : (
                ""
              )}
              <button
                className="md:px-10 md:py-3 px-3 py-2 md:me-3 me-1 md:text-base text-sm text-white bg-[#1d4645] border border-white rounded self-start font-medium"
                onClick={logout}
              >
                Logout
              </button>
            </>
          ) : null
        ) : null}
      </div>
    </nav>
  );
};

export default Nav;
