import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Nav from "./Nav";
import Landing from "./Landing";
import Login from "./Login";
import Register from "./Register";
import { useEffect, useState } from "react";
import QuizPage from "./QuizPage";
import axios from "axios";
import { base_url } from "./Constants";
import Admin from "./Admin";
import AttemptDetails from "./AttemptDetails";
import { Admin2 } from "./Admin2";
import Modal from "./Modal";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("info");
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (type, message) => {
    setModalType(type);
    setModalMessage(message);
    setShowModal(true);
  };

  const [loginState, setLoginState] = useState(false);

  // storing login info localstorage
  const handleLogin = (newLogin) => {
    let login;
    if (newLogin.isAdmin) {
      login = newLogin;
    } else {
      login = {
        loggedIn: true,
        accessToken: newLogin.access,
        refreshToken: newLogin.refresh,
        username: newLogin.name,
      };
    }
    localStorage.setItem("login", JSON.stringify(login));
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [takeIdState, setTakeIdState] = useState();
  const handleTakeIdState = (takeId) => {
    setTakeIdState(takeId);
  };

  const [disableQuizStart, setDisableQuizStart] = useState(false);

  const setQuestionId = () => {
    const login = JSON.parse(localStorage.getItem("login"));
    if (login && !takeIdState && location.pathname === "/") {
      setDisableQuizStart(true);
      axios
        .get(`${base_url}/start-quiz/`, {
          headers: {
            Authorization: `Bearer ${login.accessToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            navigate("/quiz");
            handleTakeIdState(res.data.id);
            setDisableQuizStart(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            // alert(err.response.data.error);
            openModal("warning", err.response.data.error);
            setDisableQuizStart(false);
          }
        });
    }
    if (location.pathname === "/quiz") {
      navigate("/");
    }
  };

  useEffect(
    function () {
      window.scrollTo({ top: 0, behavior: "instant" });
      if (!localStorage.getItem("login")) {
        localStorage.setItem("login", "");
      }
    },
    [location.pathname]
  );

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Nav
          loginState={loginState}
          setLoginState={setLoginState}
          setQuestionId={setQuestionId}
          disableQuizStart={disableQuizStart}
        />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Landing loginState={loginState} />} />
            <Route
              path="/login"
              element={<Login handleLogin={handleLogin} />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/quiz"
              element={
                <QuizPage
                  takeIdState={takeIdState}
                  loginState={loginState}
                  handleTakeIdState={handleTakeIdState}
                />
              }
            />
            <Route path="/verify-master" element={<Admin />} />
            <Route path="/admin-registered-users" element={<Admin2 />} />
            <Route path="/attempt/:id" element={<AttemptDetails />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          message={modalMessage}
          type={modalType}
        />
      </div>
    </>
  );
}

export default App;
