import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#1d4645] p-5 flex flex-col justify-center text-center">
      <h3 className="text-white" id="desktopFooterInitiative">
        Copyright © {currentYear}. All Rights Reserved | Developed by
        <Link to="https://5cube.io/" target="_blank">
          <span className="text-red-600"> 5Cube </span>
        </Link>{" "}
        | An initiative by Stride Events
      </h3>
      <div className="text-white" id="mobileFooterInitiative">
        <h3>Copyright © {currentYear}. All Rights Reserved</h3>
        <h3>
          Developed by
          <Link to="https://5cube.io/" target="_blank">
            <span className="text-red-600"> 5Cube </span>
          </Link>
        </h3>
        <h3>An initiative by Stride Events</h3>
      </div>
    </footer>
  );
};

export default Footer;
